var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("dashboard-top-menu", {
        attrs: { isCompanyDashboard: false, applicationId: _vm.applicationId },
        on: {
          "set-selected-dashboard": _vm.setSelectedDashboard,
          "open-add-widget": function ($event) {
            _vm.showAddWigetPopup = true
          },
        },
      }),
      _vm.activeDashboard
        ? _c("dashboard-widget-cards", {
            attrs: {
              widgetList: _vm.widgetList,
              dashboardId: _vm.activeDashboard.id,
              isCompanyDashboard: false,
              applicationId: parseInt(_vm.applicationId),
              isDashboardReadonly: _vm.isDashboardReadonly,
              isDashboardDefault: _vm.isDashboardDefault,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }